<template>
    <SmartTable url="/settings/affiliazioni-stati/index" base_path="/settings/affiliazioni-stati"></SmartTable>
</template>
<script>
 import SmartTable from "@/view/components/SmartTable.vue";
 import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

 export default {
     components: {
         SmartTable
     },
     mounted() {
         this.$store.dispatch(SET_BREADCRUMB, [
             { title: "Impostazioni", route: "/settings" },
             { title: "Stati Affiliazioni", route: "/settings/affiliazioni-stati/index" },
             { title: "Ricerca Stati Affiliazioni" }
        ]);
    },
 };
</script>
